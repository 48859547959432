import { render, staticRenderFns } from "./Resolve.vue?vue&type=template&id=61799c98&scoped=true&"
import script from "./Resolve.vue?vue&type=script&lang=ts&"
export * from "./Resolve.vue?vue&type=script&lang=ts&"
import style0 from "./Resolve.vue?vue&type=style&index=0&id=61799c98&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61799c98",
  null
  
)

export default component.exports
<template>
  <div class="index">
    <Search />

    <!-- <Resolve /> -->

    <!-- <Product /> -->

    <ResolveAll />

    <Feature />

    <!-- <Enterprise /> -->

    <Experience />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

import Search from './components/Search.vue'
import Resolve from './components/Resolve.vue'
import Product from './components/Product.vue'
import Feature from './components/Feature.vue'
import Enterprise from './components/Enterprise.vue'
import Experience from './components/Experience.vue'
import ResolveAll from './components/ResolveAll.vue'

@Component({
  components: {
    Search,
    Resolve,
    Product,
    Feature,
    Enterprise,
    Experience,
    ResolveAll
  }
})
export default class Index extends Vue {}
</script>

<style lang="sass" scope>
.index
  // background-color: #fff

  .title
    margin-top: 60px
    font-size: 30px
    font-weight: normal
    text-align: center
    color: #40549C

    span
      font-weight: bold
</style>
